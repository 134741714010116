/**
 * Imports
 */
import '../styles/_init_.sass'
import '../styles/critical.sass'


/**
 * Prototypes and utils
 */

/**
 * @param  {String} selector valid css selector
 */
function sel (selector) {
    let res = Array.from(document.querySelectorAll(selector)) || []
    if (res.length == 1) res = res[0]
    return res
}

/**
 * @param  {String} selector valid css selector
 */
HTMLElement.prototype.sel = function (selector) {
    let res = Array.from(this.querySelectorAll(selector)) || []
    if (res.length == 1) res = res[0]
    return res
}


/**
 * Vars
 */
const menu = sel('.menu')
const touchScreenView = 834


/**
 * Working functions
 */

/**
 * Inserts scripts of swarmcify
 */
function insertSwarm () {
    if (!sel('.video-placeholder.loaded')) return
    
    // let visited = sessionStorage.getItem('previously_visited')
    // if (!visited)
    //     return sessionStorage.setItem('previously_visited', 'visited')

    let sc = document.createElement('script')
    sc.type = "text/javascript";
    sc.src = "https://assets.swarmcdn.com/cross/swarmdetect.js"
    document.body.append(sc)
}

/**
 * @param  {HTMLElement} target target element
 */
function addObservers (target) {
    let io = new IntersectionObserver((entries, observer) => {

        // handling animation on scroll
        entries.forEach(entry => {
            let ratio = entry.intersectionRatio
            if (ratio <= 0) return
            
            // if directly visited using #
            if (entry.target.id == 'video-main') {
                // insert swarm scripts
                insertSwarm()
            }

            if (ratio >= 0.3) {
                entry.target.classList.remove('anime-block-ltr')
                entry.target.classList.remove('anime-block-rtl')
                entry.target.classList.remove('anime-block-btt')
                
                // .mission-statement is one of the registered item for the observer
                if (entry.target.classList.contains('mission-statement')) {
                    // inserts swarm player scripts
                    insertSwarm()
                }
            }
        })

        // the entry(section) with the highest intersect ratio
        // will be the current menu item
        let menuEntryCandidiate = entries.reduce((acc, cur) => {
            return cur.intersectionRatio > acc.intersectionRatio ? cur : acc
        }, entries[0])
        
        let ratio = menuEntryCandidiate.intersectionRatio        
        if (ratio <= 0) return

        // active corresponding menu item if more than 60% visible
        if (ratio >= 0.6) {
            let menuIdx = Number(menuEntryCandidiate.target.dataset.menuidx)
            if (Object.is(menuIdx, NaN)) return
            
            let targetItem = menu.sel('.menu-item')[menuIdx - 1]
            if (targetItem.classList.contains('current')) return

            menu.sel('.menu-item.current').classList.remove('current')
            targetItem.classList.add('current')
        }        
    }, {
        threshold: [0.3, 0.6, 0.01]
    })
    
    io.observe(target)
}



/**
 * Main
 */
let animatable = sel('.anime')
let menuSections = sel('.msec')

// intersector observer to animate and update
// menu entry when section is in the view
animatable.concat(menuSections).forEach(addObservers)

// adding observer just in case user visits
// the video section directly using # 
addObservers(sel("#video-main"))

// inserts swarm player scripts
// insertSwarm()


// menu toggle desktop
let menuToogle = sel('.nav-menu-toggle')
let nav = sel('#nav-vertical')
let socialIcons = sel('.social-icons')

if (window.innerWidth > touchScreenView) {
    menuToogle.addEventListener('mouseenter', () => {
        nav.classList.add('show-nav')
        socialIcons.classList.add('bl')
        
    })

    nav.addEventListener('mouseleave', () => {
        nav.classList.remove('show-nav')
        socialIcons.classList.remove('bl')
    })
} else {
    menuToogle.addEventListener('click', () => {
        nav.classList.toggle('show-nav')
    })
}


// listen for mutations in smartvideo container
let target = sel('#video-main')

let mutationObserver = new MutationObserver((entries, observer) => {
    // for some reason it's firing multiple times even after disconnecting
    let videoPlaceHolder = sel('.video-placeholder')
    if (videoPlaceHolder) {
        videoPlaceHolder.classList.remove('.video-placeholder')
        videoPlaceHolder.classList.add('loaded')
    }
    observer.disconnect()
})

mutationObserver.observe(target, {
    childList: true, subtree: true, attributes: true
})



const missionFeatures = sel('.mission-features')

// fetching color profile for feature icons
let color = localStorage.getItem('feature-icons-color')
if (color && color == 'crimson') {
    missionFeatures.classList.add('crimson-icons')
} 

// changin feature icon colors on pressing c
window.addEventListener('keydown', e => {
    if (e.key.toLowerCase() == 'c') {
        missionFeatures.classList.toggle('crimson-icons')
        let col = sel('.mission-features').classList.contains('crimson-icons')
        localStorage.setItem('feature-icons-color', col ? 'crimson' : 'black')
    }    
})


// square width for the pricetag left arrow
let priceTag = sel('.price-tag')
priceTag.sel('.sqr').style.width = priceTag.getBoundingClientRect().height + "px"

// MATOMO

// function insertMatomo () {
//     let sc = document.createElement('script')
//     sc.type = "text/javascript";
//     sc.async=true; 
//     sc.defer=true; 
//     sc.src = "https://s.3q.de/matomo.js"
//     document.body.append(sc)
// }

// var _paq = window._paq || [];
// _paq.push(["disableCookies"]);
// _paq.push(['trackPageView']);
// _paq.push(['enableLinkTracking']);
// _paq.push(['setTrackerUrl', 'https://s.3q.de/matomo.php']);
// _paq.push(['setSiteId', '51']);
// insertMatomo();
